import { HttpClient } from '@angular/common/http';
import { UserType } from '../models/userType';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable,throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class UserService {
  //  private currentUserSubject: BehaviorSubject<UserType>;
  public currentUser: Observable<UserType>;
  headers: any;
  // postHeaders: any;
  user: any;
  constructor(private _http: HttpClient) {
    // this.currentUserSubject = new BehaviorSubject<UserType>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  getToken() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.headers = {
      Authorization: "Token " + this.user.token,
      "Content-Type": "application/json"
    }
    return this.headers;
  }
  getUploadToken() {
    this.user = sessionStorage.getItem('token');
    this.headers = {
      Authorization: "Token " + this.user,
    }
    return this.headers;
  }

  getLogin(username: string, password: string) {
    return this._http.post<any>(environment.api_base_url + 'api/users/login', { username, password }).pipe(map(user => {
      if (user.user && user.user.token) {
        localStorage.setItem('currentUser', JSON.stringify(user.user));
        this.headers = {
          Authorization: "Token " + user.user.token,
          "Content-Type": "application/json"
        }
        // this.currentUserSubject.next(user.data);
        return user;
      } else {
        return user;
      }
    }));
  }

  downloadFile(path): any {
    return this._http.get(path, { responseType: 'blob' });
  }

  forgotPassword(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/forgotpassword', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  resetPassword(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/users/updatePassword', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  getUser(postData, headers) {
    return this._http.post<any>(environment.api_base_url + 'api/users/getUser', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  customerList() {
    return this._http.get<any>(environment.api_base_url + 'api/customers/customerList', { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  customerSearchList(postData, headers) {
    return this._http.post<any>(environment.api_base_url + 'api/customers/searchCustomer', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  getCustomer(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/customers/getCustomer', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  getTicketHistory(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/history/getHistory', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  getCallHistory(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/history/getHistory', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }
  getAgentList(e, headers) {
    return this._http.get<any>(environment.api_base_url + 'api/users/agentList?action=' + e.action + "&&user=" + e.user, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  getAgentTicketCount(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getAgentTicketCount', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }

  TicketSearch(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/searchTicket', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getMyTickets(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getAllTickets', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getAssignTickets(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getAgentTickets', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getClosedTickets(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getClosedTickets', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }
  getTickets(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/searchTickets', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getCustomerTickets(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getCustomerTickets', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getTicket(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/searchTicket', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  saveTicket(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/create', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  updateTicket(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/update', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  updateReasonTicket(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/history/saveHistory', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  assignTicket(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/assignTicket', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getTicketProcessList(postData) {
    return this._http.get<any>(environment.api_base_url + 'api/tickets/getTicketProcessList', { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getTicketTypeList(postData) {
    return this._http.get<any>(environment.api_base_url + 'api/tickets/getTicketTypeList', { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }

  getTicketSubTypeList(postData) {
    return this._http.get<any>(environment.api_base_url + 'api/tickets/getTicketSubTypeList', { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));
  }
  
getExcelFile(postData){
   return this._http.post<any>(environment.api_base_url + 'api/tickets/downloadTicketList',postData, { headers: this.getToken() })
   .pipe(map(ticket => {
    return ticket;
   }))
}
getFeedbackData(postData){
  return this._http.post<any>(environment.api_base_url + 'api/feedback-details/getFeedbackDetails',postData, { headers: this.getToken() })
  .pipe(map(ticket => {
   return ticket;
  }))
}
  UploadFile(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/comman/docUpload', postData)
      .pipe(map(doc => {
        return doc;
      }));
  }

  downloadList(e) {
    return this._http.get(environment.api_base_url + 'api/tickets/downloadList?action=' + e, {
      responseType: 'blob',
      headers: this.getToken()
    }).pipe(map(ticket => ticket));
  }
  feedbackdetails(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/feedback-details/getFeedbackDetails', postData, { headers: this.getToken() })
      .pipe(map(ticket => {
        return ticket;
      }));

    }

  logout() {
    //localStorage.removeItem('currentUser')
    localStorage.clear()
    // let currentUser;
    // this.currentUser.subscribe(res => {
    //   currentUser = res;
    // })
    // return this._http.get(environment.api_base_url + 'api/logout', {
    //   headers: new HttpHeaders().set('authorization', 'currentUser.token')})
    //   .pipe(map(res => {
    //   }));
  }


  canCurrentUser(permissionSting: string) {
    let currentUserData;
    let permission;
    this.currentUser.subscribe((user) => currentUserData = user);
    permission = (currentUserData.permissions || []);
    return (permission.indexOf(permissionSting) > -1);
  }

  _errorHandler(error: Response) {
    return Observable.throw(error || 'server error');
  }

  checkPermissionStatus(permission: string) {
    let allowed_permission_list = [];
    allowed_permission_list = JSON.parse(localStorage.getItem('allowed_permission_list'))
    if (allowed_permission_list.includes(permission)) {
      return true;
    }
    return false;
  }
  
  uploadMultipleFiles(senddata) {
    return this._http.post<any>(environment.api_base_url + 'api/comman/multiDocsUpload', senddata, { headers: this.getUploadToken() })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((errorRes) => {
          // console.log(errorRes)
          let errormessage = "An error Occured";
          if (!errorRes.error || !errorRes.error.message) {
            return throwError(errormessage);
          }
          return throwError(errorRes);
        })
      );
    }

  getDraftHistory(postData) {
    return this._http.post<any>(environment.api_base_url + 'api/tickets/getTicketDraftHistory', postData, { headers: this.getToken() })
      .pipe(map(user => {
        return user;
      }));
  }
}
