<div *ngIf="binPopupFlag=='callPopup'">
<div class="modal-header">
    <h4 class="modal-title">{{ data.message }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    Kindly confirm the details or else click on edit to change the details.
      </div>
    <div class="modal-footer">
        <div class="col-md-6">
      <button mat-flat-button id="no-button"  type="button" class="btn btn-danger" [mat-dialog-close]="flag" >Edit</button>
      </div>
      <div class="col-md-6">
      <button   mat-flat-button id="yes-button" type="button" class="btn btn-primary" [mat-dialog-close]="true">Confirm</button>
    </div>
    </div>
  </div>
    <div *ngIf="binPopupFlag =='ErrorPopup'">
      <div class="modal-header hdr-error">
        <h4 class="modal-title">ERROR!!</h4>
          <button type="button" class="close error-btn-close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    <div class="modal-body">
      <div class="modal-body model-titel-per">
        <div class="clearfix"> The entered BIN Range doesn't match with any plans currently, </div>
        <div class="clearfix"> Please Re-enter Correct BIN Range.</div>

          </div>
        </div>
      <div class="modal-footer">
          <div class="col-xs-12 col-lg-12">
        <button mat-flat-button id="no-button"  type="button" class="btn btn-danger" [mat-dialog-close]="flag" >Close</button>
        </div>
      </div>
    </div>

    <div *ngIf="binPopupFlag =='searchPopup'">
      <div class="modal-header hdr-error ">
        <h4 class="modal-title">ALERT!!</h4>
          <button type="button" class="close error-btn-close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    <div class="modal-body">
      <div class="modal-body  mweb-pad-rlt">
        <div class="clearfix">The Searched user doesn't exist. </div>
        <div class="clearfix"> However,tickets for non-booking related query can be created or new Registration can be done.</div>
          </div>
        </div>
      <div class="modal-footer">
          <div class="col-xs-12 col-lg-12 mweb-rtl-0">
        <button mat-flat-button id="no-button"  type="button" class="btn btn-danger mweb-fullwidth" [mat-dialog-close]="flag" >Close</button>
        <button mat-flat-button id="no-button"  type="button" class="btn btn-danger mweb-fullwidth" (click)="newRegistration()" [mat-dialog-close]="flag" >New Registration</button>
        </div>
      </div>
    </div>
    
    <div *ngIf="binPopupFlag =='resetpassword'">
      <div class="modal-header modal-headerBrbN">
        <button type="button" class="close error-btn-close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body  mweb-pad-rlt">
          <div class="check-circle"><i class="fa fa-check-circle " aria-hidden="true"></i></div>
          <div class="clearfix text-center chngs-ok">
            Password changed
            <br>
            successfully
          </div>
        </div>
      </div>
      <div class="modal-footer modal-footerBrbN">
        <div class="col-xs-12 col-lg-12 mweb-rtl-0">
          <button mat-flat-button id="no-button" type="button" class="btn btn-danger mweb-fullwidth btnwidth"
            [mat-dialog-close]="true">Ok</button>
        </div>
      </div>
    </div>
    <div *ngIf="binPopupFlag=='agentPopup'">
      <div class="modal-header">
          <h4 class="modal-title">{{ data.message }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body model-title">
            <div class="conf-title">
              Are you sure you want to update the changes
            </div>
            </div>
          <div class="modal-footer m-b-10">
              <div class="col-md-12 col-lg-12">
                <button   mat-flat-button id="yes-button" type="button" class="btn confirm-globel" [mat-dialog-close]="true">Yes</button>
                <button mat-flat-button id="no-button"  type="button" class="btn editi-globel" [mat-dialog-close]="false" >No</button>
          </div>
          </div>
    </div>
    <div *ngIf="binPopupFlag=='draftDetails'">
      <div class="modal-header">
        <h4 class="modal-title text-center">Confirmation</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
                      <span aria-hidden="true">&times;</span>
                    </button> -->
      </div>
      <div class="modal-body">
        <div class="conf-title">
          If clicked confirm you won't be able to edit the details.
          Please click on confirm if the details are correct or click back.
        </div>
      </div>
      <div class="modal-footer modalfooter m-b-10">
        <div class="row">
          <div class="col-md-6 col-lg-6">
            <button mat-flat-button id="no-button" type="button" class="btn editi-globel"
              [mat-dialog-close]="false">Back</button>
          </div>
          <div class="col-md-6 col-lg-6">
            <button mat-flat-button id="yes-button" type="button" class="btn confirm-globel"
              [mat-dialog-close]="true">Confirm</button>
          </div>
        </div>
      </div>
    </div>